"use client";
import Image from "next/image";
import nhakhoahubWhite from "@/public/image/nhakhoahub-white.svg";
import {
  Form,
  Row,
  Col,
  Input,
  Popover,
  Select,
  AutoComplete,
  Button,
} from "antd";
import "./consultation-registration.scss";
import { useEffect, useState, useRef, useMemo } from "react";
import ImageCommon from "../image/image";
import { LoadingOutlined } from "@ant-design/icons";
import logo_doc from "../../../public/image/logo_doc.webp";
import notification from "antd/es/notification";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import { strVNForSearch } from "@/src/util/util";
import { getClinicByName, createContact } from "./service";
import { PROVINCE, SERVICE } from "@/src/const/variable";
import useWindowSize from "@/src/hooks/useWindowSize";

export default function ConsultationRegist() {
  const [showDistrict, setShowDistrict] = useState(false);
  const [openSelectAddress, setOpenSelectAddress] = useState(false);
  const [listProvince, setListProvince] = useState(PROVINCE);
  const [listDistrict, setListDistrict] = useState([]);
  const [listDistrictNoChange, setListDistrictNoChange] = useState([]);
  const [currentProvince, setCurrentProvince] = useState(null);
  const [currentDistrict, setCurrentDistrict] = useState(null);
  const scrollableDivRef = useRef(null);
  const [optionsName, setOptionsName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successSent, setSuccessSent] = useState(false);
  const width = useWindowSize();
  const optionsCategoryService = useMemo(
    () => SERVICE.map((item) => ({ value: item.id, label: item.name })),
    [SERVICE]
  );

  const requestSearch = async (searchText) => {
    if (searchText && currentProvince) {
      const result = await getClinicByName(
        searchText,
        currentProvince,
        currentDistrict
      );
      const newOption = result?.map((item) => {
        return {
          key: item.id,
          value: item.name,
          label: (
            <div className="item-result-search">
              <ImageCommon data={item} style={"logo-on-search"} />
              <span>{item.name}</span>
            </div>
          ),
        };
      });
      setOptionsName(newOption);
    }
  };

  //debounce
  const debounceWrapper = (callback, delay = 300) => {
    return _.debounce(callback, delay);
  };
  const handleSearchProvince = (value) => {
    const filterProvince = PROVINCE.filter((item) =>
      strVNForSearch(item.name).includes(strVNForSearch(value))
    );
    setListProvince(filterProvince);
  };

  const handleSearchDistrict = (value) => {
    const filterDistrict = listDistrictNoChange.filter((item) =>
      strVNForSearch(item.name).includes(strVNForSearch(value))
    );
    setListDistrict(filterDistrict);
  };

  const debounceOnChangeInputProvice = debounceWrapper(handleSearchProvince);
  const debounceOnChangeInputDistrict = debounceWrapper(handleSearchDistrict);
  const debounceSetShowDistrict = debounceWrapper(() => setShowDistrict(false));
  const debounceOnChangeInput = debounceWrapper(requestSearch);
  //

  const filterOption = (input, option) => {
    if (option.props.label) {
      return strVNForSearch(option.props.label).includes(strVNForSearch(input));
    } else {
      return false;
    }
  };
  const onSearch = (searchText) => {
    debounceOnChangeInput(searchText);
  };

  const [form] = Form.useForm();
  const FormItem = Form.Item;

  useEffect(() => {
    form.setFieldsValue({
      clinic_name: null,
      clinic_id: null,
    });
  }, [currentDistrict, currentProvince]);

  const onFinish = async () => {
    setLoading(true);

    const fieldValue = await form.validateFields();
    const contact_clinic_id = form.getFieldValue("clinic_id");
    const body = {
      ...fieldValue,
      province_id: form.getFieldValue("province_id"),
      contact_clinic: contact_clinic_id ? [parseInt(contact_clinic_id)] : [],
    };
    const result = await createContact(body);
    if (!result) {
      notification.error({
        message: `Có lỗi xảy ra!`,
        description: "Vui lòng thử lại",
        placement: "top",
      });
    } else {
      setSuccessSent(true);
    }
    setLoading(false);
  };

  return (
    <div className="wp-consultation">
      <div className="consultation">
        <div className="introduce">
          <Image
            src={nhakhoahubWhite}
            className="logo"
            alt="NhaKhoaHub"
            loading="lazy"
          ></Image>
          Giúp bạn kết nối tới các cơ sở nha khoa uy tín gần nhất
        </div>
        {!successSent && (
          <div className="regist-form">
            <div className="title-mobile">
              Giúp bạn kết nối tới các cơ sở nha khoa uy tín gần nhất!
            </div>
            <div className="form-title">Đăng ký nhận tư vấn miễn phí!</div>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={onFinish}
            >
              <Row gutter={[30, 0]}>
                <Col xl={12} sm={12} xs={12}>
                  <FormItem
                    name="customer_name"
                    label={"Họ và tên:"}
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập họ và tên",
                      },
                    ]}
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col xl={12} sm={12} xs={12}>
                  <FormItem
                    name="phone"
                    label={"Số điện thoại:"}
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập số điện thoại!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const phoneNumberRegex = /^0\d{9}$/; // Định dạng số điện thoại: 10 hoặc 11 chữ số
                          if (value && !phoneNumberRegex.test(value)) {
                            return Promise.reject(
                              "Số điện thoại không hợp lệ!"
                            );
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col xl={12} sm={12} xs={24}>
                  <FormItem
                    name="district_id"
                    label={"Khu vực:"}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          const province_id = form.getFieldValue("province_id");
                          if (!province_id) {
                            return Promise.reject("Vui lòng chọn khu vực");
                          }
                          if (!value) {
                            return Promise.reject("Vui lòng chọn quận huyện");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Popover
                      placement="bottom"
                      overlayClassName="wp-popover-select"
                      align={
                        width < 600
                          ? {
                              points: ["tc", "bc"],
                              offset: [0, -71],
                            }
                          : {}
                      }
                      title={
                        <div className="wp-title-select">
                          <div className="wp-left-select">
                            {showDistrict ? (
                              <div
                                className="button-back"
                                onClick={() => {
                                  setShowDistrict(false);
                                }}
                              >
                                <ArrowLeftOutlined />
                              </div>
                            ) : null}
                            <div className="title-select">
                              {!showDistrict ? "Tỉnh thành" : "Quận huyện"}
                            </div>
                          </div>
                          <div
                            className="button-check-done"
                            onClick={() => {
                              setOpenSelectAddress(false);
                            }}
                          >
                            <CheckOutlined />
                          </div>
                        </div>
                      }
                      motion={null}
                      content={
                        !showDistrict ? (
                          <div className="wp-modal-select">
                            <FormItem name="text_search_province">
                              <Input
                                placeholder="Tỉnh thành"
                                onChange={(event) => {
                                  event.persist();
                                  debounceOnChangeInputProvice(
                                    event.target.value
                                  );
                                }}
                                prefix={<SearchOutlined />}
                              />
                            </FormItem>
                            <div className="wp-list-select">
                              {listProvince?.map((item) => {
                                return (
                                  <div
                                    className="wp-item-choose"
                                    key={item.id}
                                    onClick={() => {
                                      // setIdProvince(item.id);
                                      setCurrentProvince(item.id);
                                      setCurrentDistrict(null);
                                      form.setFieldsValue({
                                        province_id: item.id,
                                        provinceName: item.name,
                                        district_id: null,
                                        districtName: null,
                                        text_search_districts: null,
                                      });
                                      setListDistrictNoChange(item?.district);
                                      setListDistrict(item?.district);
                                      setShowDistrict(true);
                                    }}
                                  >
                                    {item.name}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : (
                          <div className="wp-modal-select-2">
                            <FormItem name="text_search_districts">
                              <Input
                                placeholder="Quận huyện"
                                onChange={(event) => {
                                  event.persist();
                                  debounceOnChangeInputDistrict(
                                    event.target.value
                                  );
                                }}
                                prefix={<SearchOutlined />}
                              />
                            </FormItem>
                            <div
                              className="wp-list-select-2"
                              ref={scrollableDivRef}
                            >
                              {listDistrict.map((item) => {
                                return (
                                  <div
                                    className="wp-item-choose"
                                    key={item.id}
                                    onClick={() => {
                                      setCurrentDistrict(item.id);
                                      form.setFieldsValue({
                                        district_id: item.id,
                                        districtName: item.name,
                                      });
                                      setOpenSelectAddress(false);
                                      // setShowDistrict(false);
                                      debounceSetShowDistrict();
                                    }}
                                  >
                                    {item.name}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )
                      }
                      onOpenChange={(newOpen) => {
                        setOpenSelectAddress(newOpen);
                      }}
                      destroyTooltipOnHide={true}
                      trigger="click"
                      open={openSelectAddress}
                    >
                      <div className="wp-button-choose">
                        <div className="button-choose">
                          {form.getFieldValue("districtName")
                            ? form.getFieldValue("districtName") + ", "
                            : null}
                          {form.getFieldValue("provinceName")}
                          {!form.getFieldValue("districtName") &&
                            !form.getFieldValue("provinceName") && (
                              <div className="un-choose">Chọn khu vực</div>
                            )}
                        </div>
                      </div>
                    </Popover>
                  </FormItem>
                </Col>
                <Col xl={12} sm={12} xs={24}>
                  <FormItem
                    name="category_service"
                    className="category-service-input"
                    label={"Dịch vụ tư vấn:"}
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn dịch vụ",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      options={optionsCategoryService}
                      placeholder="Chọn dịch vụ"
                      filterOption={filterOption}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                      dropdownAlign={{
                        points: ["tl", "bl"], // align dropdown bottom-left to top-left of input element
                        offset: [0, -4], // align offset
                        overflow: {
                          adjustX: 0,
                          adjustY: 0, // do not auto flip in y-axis
                        },
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    name="clinic_name"
                    className="clinic-input"
                    label={"Phòng khám:"}
                  >
                    <AutoComplete
                      options={optionsName}
                      placeholder="Nhập tên phòng khám"
                      className="wrapper-input-search"
                      notFoundContent={
                        !currentProvince
                          ? "Vui lòng chọn khu vực"
                          : "Nhập tên phòng khám"
                      }
                      onSearch={onSearch}
                      onChange={(data, option) => {}}
                      onSelect={async (data, option) => {
                        // setInputValue(option.label);
                        form.setFieldsValue({
                          clinic_name: option.value,
                          clinic_id: option.key,
                        });
                      }}
                      filterOption={false}
                      onBlur={() => {
                        setOptionsName([]);
                      }}
                      dropdownAlign={{
                        points: ["tl", "bl"], // align dropdown bottom-left to top-left of input element
                        offset: [0, -4], // align offset
                        overflow: {
                          adjustX: 0,
                          adjustY: 0, // do not auto flip in y-axis
                        },
                      }}
                      allowClear={true}
                    />
                  </FormItem>
                </Col>
                <Col span={24} className="form-note">
                  LƯU Ý: Các trường có dấu{" "}
                  <span style={{ color: "#d41615" }}>*</span> là trường bắt
                  buộc.
                </Col>
                <Col span={24}>
                  <FormItem className="wrapper-button-submit">
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="button-submit"
                    >
                      {loading ? <LoadingOutlined /> : "Đăng ký ngay"}
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        )}
        {successSent && (
          <div className="success-form">
            <Image
              src={logo_doc}
              alt="logo_doc"
              className="logo_doc"
              loading="lazy"
            />
            <div className="title-line-1">
              Nền tảng tìm kiếm nha khoa & review nha khoa hàng đầu
            </div>
            <div className="title-success">Bạn đã đăng ký thành công!</div>
            <div className="title-line-3">
              <div>
                <b>NhaKhoaHub</b> sẽ liên hệ lại ngay với bạn trong vòng 30 phút
                tới.
              </div>
              Cảm ơn bạn đã tin tưởng và sử dụng dịch vụ của chúng tôi!
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
