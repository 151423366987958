import wpAxios from "@/src/util/request";
import { BASE_URL } from "@/src/const/const";
import { URL_BLOG, REVALIDATE } from "@/src/const/const";

// import { cache } from "react";
export async function getAllService() {
  const result = await wpAxios().get(`${BASE_URL}/admin/category-service/all`);
  // console.log("result", result);
  return result.data;
}

export async function getDistrictByProvinceId(province_id) {
  const result = await wpAxios().get(
    `${BASE_URL}/admin/district/get-popular-by-province-id/${province_id}`
  );
  return result.data;
}

export async function getAllProvince() {
  const result = await wpAxios().get(
    `${BASE_URL}/admin/province/include-district`
  );
  return result.data;
}
export async function getAllWeekDay() {
  const result = await wpAxios().get(`${BASE_URL}/admin/weekday`);

  return result.data;
}
export async function getBlog(current, categorySlug, pageSize) {
  const result = await wpAxios().get(
    `${URL_BLOG}/wp-admin/admin-ajax.php?action=get_all_posts`,
    {
      params: {
        current: current ? current : null,
        categorySlug: categorySlug ? categorySlug : null,
        pageSize: pageSize ? pageSize : 12,
      },
    }
  );
  return result.data;
  // console.log(
  //   "fetch ",
  //   `${URL_BLOG}/wp-admin/admin-ajax.php?action=get_all_posts&current=${
  //     current ? current : null
  //   }&pageSize=${pageSize ? pageSize : 12}`
  // );
  // const result = await fetch(
  //   `${URL_BLOG}/wp-admin/admin-ajax.php?action=get_all_posts&current=${
  //     current ? current : null
  //   }&categorySlug=${categorySlug ? categorySlug : null}
  //   &pageSize=${pageSize ? pageSize : 12}`,
  //   {
  //     method: "GET", // *GET, POST, PUT, DELETE, etc.
  //     cache: "force-cache",
  //   }
  // );
  // const res = await result.json();
  // console.log("res", res)
  // return res;
}

export async function getAllCategoryBlog() {
  const result = await fetch(
    `${URL_BLOG}/wp-admin/admin-ajax.php?action=get_all_category`,
    {
      method: "GET",
      // cache: "force-cache",
      next: { revalidate: REVALIDATE },
    }
  );
  const res = await result.json();
  return res;
}
export async function getAllSlug() {
  const result = await wpAxios().get(
    `${BASE_URL}/parse-slug/get-all-slug-for-sitemap`
  );

  // const result = await fetch(
  //   `${BASE_URL}/parse-slug/get-all-slug-for-sitemap`,
  //   {
  //     method: "GET",
  //     cache: "force-cache",
  //   }
  // );
  // const res = await result.json();
  return result?.data;
}
export async function getSlugsForPhongKham() {
  const result = await wpAxios().get(
    `${BASE_URL}/parse-slug/for-phong-kham-sitemap`
  );

  // const result = await fetch(
  //   `${BASE_URL}/parse-slug/get-all-slug-for-sitemap`,
  //   {
  //     method: "GET",
  //     cache: "force-cache",
  //   }
  // );
  // const res = await result.json();
  return result?.data;
}

export async function getAllPostBlog() {
  // console.log(
  //   "fetch ",
  //   `${URL_BLOG}/wp-admin/admin-ajax.php?action=get_all_posts`
  // );
  // const result = await fetch(
  //   `${URL_BLOG}/wp-admin/admin-ajax.php?action=get_all_posts`,
  //   {
  //     method: "GET", // *GET, POST, PUT, DELETE, etc.
  //     cache: "force-cache",
  //   }
  // );
  // const res = await result.json();
  // return res;
  const result = await wpAxios().get(
    `${URL_BLOG}/wp-admin/admin-ajax.php?action=get_all_posts`
  );
  return result.data;
}

export async function amountContact() {
  const result = await wpAxios().get(`${BASE_URL}/contact/all`);
  return result.data.total;
}

export async function getListService() {
  const result = await wpAxios().get(`${BASE_URL}/admin/category-service`);
  return result.data;
}

export async function createContact(params) {
  try {
    let res = await wpAxios().post(
      `${BASE_URL}/contact`,
      {
        customer_name: params.customer_name,
        customer_phone_number: params.customer_phone_number,
        province_id: params.province_id,
        district_id: params.district_id,
        category_service_id: params.category_service_id,
        contact_clinic: [params.clinic_id],
        order_date: params.order_date,
        order_time: params.order_time,
        landing_page_url: params.landing_page_url,
        referrer: params.referrer,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (res?.status === 200) {
    }
    return res;
  } catch (error) {
    return false;
  }
}

export async function createContactAdvise(params, type) {
  const body = {
    ...params,
    type: type,
  };
  try {
    let res = await wpAxios().post(`${BASE_URL}/contact-advice`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res?.status === 200) {
    }
    return res;
  } catch (error) {
    return false;
  }
}

export async function getBlogDetail(slug) {
  // console.log(
  //   "fetch ",
  //   `${URL_BLOG}/wp-admin/admin-ajax.php?action=get_detail_post&postSlug=${slug}`
  // );
  // const result = await fetch(
  //   `${URL_BLOG}/wp-admin/admin-ajax.php?action=get_detail_post&postSlug=${slug}`,
  //   {
  //     method: "GET", // *GET, POST, PUT, DELETE, etc.
  //     cache: "force-cache",
  //   }
  // );
  // const res = await result.json();
  // return res;
  const result = await wpAxios().get(
    `${URL_BLOG}/wp-admin/admin-ajax.php?action=get_detail_post&postSlug=${slug}`
  );
  return result.data;
}

export async function getBlogRelated(id, categorySlug, limit) {
  const result = await wpAxios().get(
    `${URL_BLOG}/wp-admin/admin-ajax.php?action=get_all_posts&current=1&pageSize=${limit}&categorySlug=${categorySlug}&postId=${id}`
  );
  return result.data;
}

export async function getBlogPopular(limit) {
  const result = await wpAxios().get(
    `${URL_BLOG}/wp-admin/admin-ajax.php?action=get_popular_posts&limit=${limit}`
  );
  return result.data;
}

export async function getBlogNew(limit) {
  const result = await wpAxios().get(
    `${URL_BLOG}/wp-admin/admin-ajax.php?action=get_news_posts&limit=${limit}`
  );
  return result.data;
}

export async function editUserInfo(body, access_token) {
  try {
    let res = await wpAxios().patch(
      `${BASE_URL}/public-user/update-info`,
      { ...body },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    // if (res?.status === 200) {
    //   message.success('Thành công');
    // }
    return res.data;
  } catch (error) {
    return false;
  }
}

export async function socialLogin(body) {
  try {
    const res = await wpAxios().post(`${BASE_URL}/auth/socialLogin`, {
      ...body,
    });
    return res;
  } catch (error) {}
}

export async function currentUser(id, access_token) {
  const result = await wpAxios().get(`${BASE_URL}/public-user/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });
  return result.data;
}

export async function searchClinics(name, limit) {
  try {
    const { data } = await wpAxios().get(`${BASE_URL}/clinic/search`, {
      params: {
        page: 1,
        limit: limit ? limit : 20,
        name: name ? name : null,
      },
    });
    return data;
  } catch (error) {
    return false;
  }
}
export async function getDiariesHome(type) {
  const result = await wpAxios().get(`${BASE_URL}/diary-post/findFilter`, {
    params: {
      page: 1,
      limit: 12,
      type: type ? type : null,
    },
  });
  return result.data;
}

export async function getPostsHome() {
  const result = await wpAxios().get(`${BASE_URL}/admin/post/public`, {
    params: {
      page: 1,
      limit: 18,
    },
  });
  return result.data;
}

export async function getContentHighlight() {
  const result = await wpAxios().get(
    `${BASE_URL}/content-highlight?page=1&limit=5&flag=true'`
  );
  return result.data;
}

export async function getBlogDetailPreview(id) {
  const result = await wpAxios().get(
    `${URL_BLOG}/wp-admin/admin-ajax.php?action=get_detail_post_all_status&postId=${id}`
  );
  return result.data;
}

export async function createContactAdvice(body) {
  try {
    const result = await wpAxios().post(`${BASE_URL}/contact-advice`, body);
  } catch (error) {
    console.log(error);
  }
}
