import { useState, useEffect } from 'react';

function useSessionUser(svSession, session) {
  const [sessionUser, setSessionUser] = useState(svSession?.user);

  useEffect(() => {
    if (session) {
      setSessionUser(session.user);
    }
  }, [session]);

  return sessionUser;
}

export default useSessionUser;
