import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-cry-unselect.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-cry.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-dislike.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-heart-unselect.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-heart.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-laugh-unselect.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-laugh.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-like.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-thumb-down.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icon/icon-thumb-up.svg");
;
import(/* webpackMode: "eager" */ "/app/public/image/background-CTA-mobile.webp");
;
import(/* webpackMode: "eager" */ "/app/public/image/background-CTA.webp");
;
import(/* webpackMode: "eager" */ "/app/public/image/boc-rang-su.svg");
;
import(/* webpackMode: "eager" */ "/app/public/image/dan-rang-su.svg");
;
import(/* webpackMode: "eager" */ "/app/public/image/logo-nha-khoa-hub-cta-mobile.png");
;
import(/* webpackMode: "eager" */ "/app/public/image/logo-nha-khoa-hub-cta.png");
;
import(/* webpackMode: "eager" */ "/app/public/image/nieng-rang-tham-my.svg");
;
import(/* webpackMode: "eager" */ "/app/public/image/trong-rang-implant.svg");
;
import(/* webpackMode: "eager" */ "/app/src/app/page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/home/banner-top.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/home/consultation-registration.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/home/diary-home.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/home/introduce-section.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/home/remarkable-dt.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/component/home/review-dt.js");
