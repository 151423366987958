"use client";
import { Row, Col } from "antd";
import Image from "next/image";
import "./banner-top.scss";
import StatisticsImg from "@/public/image/key-statistic.svg";
import FormSearchReduce from "./form-search-reduce";
import { useEffect } from "react";
// import "aos/dist/aos.css";
// import "@/src/style/aos.css";
import AOS from "aos";
import CountUp from "react-countup";
import imageWomen from "@/public/image/banner-women.webp";
import imageWomenMobile from "@/public/image/banner-women_mb.webp";
import imgDecor1 from "@/public/image/backgr-above.webp";
import imgDecor2 from "@/public/image/backgr-below.webp";
import useWindowSize from "@/src/hooks/useWindowSize";

const KEY_STATISTIC = [
  {
    statistics: 5000,
    key: "Nha khoa có trên hệ thống",
  },
  {
    statistics: 5000,
    key: "Showcase từ nha khoa",
  },
  {
    statistics: 50000,
    key: "Nhật ký từ khách hàng",
  },
];

export default function BannerTop() {
  const { width } = useWindowSize();
  const isMobile = width < 1200;
  useEffect(() => {
    AOS.init({
      duration: 900, // Thời gian hoạt ảnh (ms)
      offset: 50, // Khoảng cách từ phần tử để bắt đầu hoạt ảnh
      easing: "ease-in-out", // Kiểu easing
      once: true,
    });
  }, []);

  return (
    <div className="banner-top">
      <div className="wp-banner-for-css">
        <div className="wp-banner-content">
          <div className="banner-content">
            <div data-aos="fade-down" style={{ width: "100%" }}>
              <div className="title">
                Tìm&nbsp;kiếm nha&nbsp;khoa uy&nbsp;tín phù&nbsp;hợp nhất với
                bạn
              </div>
            </div>
            <Row
              gutter={[16, 0]}
              className="wp-statistics"
              data-aos="fade-up"
              data-aos-delay="250"
            >
              {KEY_STATISTIC.map((item, index) => {
                return (
                  <Col xl={7} md={7} xs={8} sm={8} key={index}>
                    <div className="item-statistics">
                      <div className="content">
                        <div className="statistics">
                          <CountUp
                            start={0}
                            end={item.statistics}
                            duration={4.5}
                            delay={0.5}
                          />
                          +
                          <div className="layer-statistics">
                            {" "}
                            <CountUp
                              start={0}
                              end={item.statistics}
                              duration={4.5}
                              delay={0.5}
                            />
                            +
                          </div>
                        </div>
                        <div className="key">
                          {item.key}
                          <div className="layer-key">{item.key}</div>
                        </div>
                      </div>
                      <Image
                        src={StatisticsImg}
                        alt="img"
                        className="statistics-img"
                        loading="eager"
                      ></Image>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          <div className="banner-search">
            <FormSearchReduce />
          </div>
          {isMobile && (
            <div className="banner-slider-mobile" style={{ margin: "-8px" }}>
              <Image
                src={imageWomenMobile}
                alt="NhaKhoaHub"
                className="home-banner-img"
                priority
              />
            </div>
          )}
        </div>
      </div>
      {!isMobile && (
        <div className="wp-banner-for-css for-banner-image">
          <div className="wp-banner-image">
            <Image
              src={imageWomen}
              className="image-women"
              alt="NhaKhoaHub"
              priority
            ></Image>
          </div>
        </div>
      )}
      <Image src={imgDecor1} className="img-decor-above" alt="decor" />
      <Image
        src={imgDecor2}
        className="img-decor-below"
        alt="decor"
        priority={isMobile ? true : false}
      />
    </div>
  );
}
