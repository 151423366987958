import wpAxios from "@/src/util/request";
import { BASE_URL } from "@/src/const/const";
export async function getClinicByName(name, province_id, district_id) {
  const result = await wpAxios().get(`${BASE_URL}/clinic/name?name=${name}`, {
    params: {
      province_id: province_id ? province_id : null,
      district_id: district_id ? district_id : null,
    },
  });
  return result.data;
}

export async function createRegistrationClinic(params) {
  const data = {
    name: params.name,
    phone_number: params.phone_number,
    email: params.email,
    clinic_name: params.clinic_name,
    province_id: params.province_id,
    district_id: params.district_id,
    note: params.note,
    campaign: params.campaign,
  };
  try {
    let res = await wpAxios().post(`${BASE_URL}/registration_clinic`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res?.status === 200) {
    }
    return res;
  } catch (error) {
    return false;
  }
}

export async function createContact(params) {
  try {
    let res = await wpAxios().post(
      `${BASE_URL}/contact`,
      {
        customer_name: params.customer_name,
        customer_phone_number: params.phone,
        province_id: params.province_id,
        district_id: params.district_id,
        category_service_id: params.category_service,
        contact_clinic: params.contact_clinic,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (res?.status === 200) {
    }
    return res;
  } catch (error) {
    return false;
  }
}

export async function getListProvince() {
  const result = await wpAxios().get(`${BASE_URL}/admin/province`);
  return result.data;
}

export async function getListDistrict(id) {
  const result = await wpAxios().get(
    `${BASE_URL}/admin/district/get-by-province-id/${id}`
  );
  return result.data;
}
